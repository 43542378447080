import React, { Component } from 'react';
import LoadingComponent from '../../common/LoadingComponent';
import { getLocale, getTranslation } from '../../../translation/translations';
import { withRouter } from 'next/router';
import { CompanyService } from '../../../services/company.service';
import Image from 'next/image';

export interface SectionCompanyInfoNewsProps {
  companyLinks: any;
  ticker: string;
}

interface SectionCompanyInfoNewsState {
  activePage: number;
  activeTab: string;
  allNewsReceived: boolean;
  pressReleaseReceived: boolean;
}

function truncate(value: string, limit = 100): string {
  return typeof value === 'string' ? `${value.substr(0, limit)}${value.length > limit ? '...' : ''}` : '';
}

function decode(str) {
  if (typeof str === 'string' && str.replaceAll) {
    return str.replaceAll('&nbsp;', '\xa0');
  } else {
    return str;
  }
}

function formatDate(date: string) {
  let myDate = new Date(date);
  return myDate.toLocaleDateString(getLocale(), { year: 'numeric', month: 'long', day: 'numeric' });
}

/*function LazyNewsImage({data,lazyLoad}) {
  return <div className="rss-item-image">{data.og?.image &&
    (
      (data.og.image.width && data.og.image.height)
        ?
        <Image priority={!lazyLoad} alt="" src={data.og.image.url} width={data.og.image.width} height={data.og.image.height} />
        :
        <img alt="" src={data.og.image.url} width={data.og.image.width} height={data.og.image.height} />
    )
  }</div>
}*/

function LazyNewsImageOld({
  data,
  lazyLoad,
  defaultImage,
  disableImage = false,
  fallbackImg = null,
  fallbackImgSize = null,
}) {
  const [error, setError] = React.useState(false);
  const image = data.og?.image || defaultImage;
  return (image && !disableImage && (!error || fallbackImg)) || (!image && fallbackImg) ? (
    <div
      className={`rss-item-image ${(error && fallbackImg) || (!image && fallbackImg) ? 'rss-item-placeholder-image' : ''}`}
      data-disable-image={disableImage.toString()}
    >
      {error || !image ? (
        fallbackImg && <img className={'placeholder-image'} alt="" src={fallbackImg} />
      ) : image ? (
        image.width && image.height ? (
          <Image
            priority={!lazyLoad}
            alt=""
            src={image.url}
            width={image.width}
            height={image.height}
            onError={() => setError(true)}
          />
        ) : (
          <img
            alt=""
            src={image.url}
            width={image.width}
            height={image.height}
            decoding="async"
            loading={lazyLoad ? 'lazy' : 'eager'}
            onError={() => setError(true)}
          />
        )
      ) : null}
    </div>
  ) : null;
}

function LazyNewsImage({
  data,
  lazyLoad,
  defaultImage,
  disableImage = false,
  fallbackImg = null,
  fallbackImgSize = null,
}) {
  const [error, setError] = React.useState(false);
  const image = data.og?.image || defaultImage;

  return (image && !disableImage && (!error || fallbackImg)) || (!image && fallbackImg) ? (
    <div
      className={`rss-item-image ${(error && fallbackImg) || (!image && fallbackImg) ? 'rss-item-placeholder-image' : ''}`}
      data-disable-image={disableImage.toString()}
    >
      {error || !image ? (
        fallbackImg && <Image className="placeholder-image" alt="" src={fallbackImg} layout="fill" objectFit="cover" />
      ) : image ? (
        <Image
          priority={!lazyLoad}
          alt=""
          src={image.url}
          layout="fill"
          objectFit="cover"
          onError={() => setError(true)}
        />
      ) : null}
    </div>
  ) : null;
}

export function NewsItem({
  data,
  details,
  showPreview,
  children,
  linkGenerator,
  ticker,
  lazyLoadImage = true,
  disableImage = false,
  seekingAlpha = false,
  oldVersion = false,
}: {
  data;
  details?;
  showPreview?;
  children?;
  ticker?;
  linkGenerator?: (string) => string;
  lazyLoadImage?: boolean;
  disableImage?: boolean;
  seekingAlpha?: boolean;
  oldVersion?: boolean;
}) {
  if (oldVersion) {
    return (
      <div
        className={'rss-item rss-item-old' + (disableImage ? ' rss-item-image-disabled' : '')}
        data-disable-image={disableImage.toString()}
      >
        <div className="rss-item-row">
          <LazyNewsImageOld
            data={data}
            lazyLoad={lazyLoadImage}
            disableImage={disableImage}
            defaultImage={
              ticker
                ? {
                    url: 'https://iocharts-data.fra1.digitaloceanspaces.com/logos/' + ticker.toUpperCase() + '.png',
                    width: 128,
                    height: 128,
                  }
                : null
            }
            fallbackImg={seekingAlpha ? 'https://iocharts-data.fra1.digitaloceanspaces.com/logos/sa_logo.webp' : null}
            fallbackImgSize={{ width: 128, height: 128 }}
          />
          <div className="rss-item-col">
            <div className="rss-item-title">
              <a href={linkGenerator ? linkGenerator(data.link) : data.link} target="_blank" rel="noopener noreferrer">
                <div dangerouslySetInnerHTML={{ __html: data.title }} />
              </a>
            </div>
            {showPreview ||
            (showPreview === undefined &&
              (data.rssUrl?.startsWith('https://news.google.com') ||
                data.link?.startsWith('https://seekingalpha.com'))) ? (
              data.og?.description ? (
                <div className="rss-item-description">{truncate(decode(data.og.description), 200)}</div>
              ) : (
                <div className="rss-item-description">
                  <a
                    href={linkGenerator ? linkGenerator(data.link) : data.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {data.sourceUrl}
                  </a>
                </div>
              )
            ) : details?.language === 'hu' && data.og?.description ? (
              <div className="rss-item-description">{truncate(decode(data.og.description), 200)}</div>
            ) : (
              <div className="rss-item-description">{truncate(decode(data.contentSnippet), 200)}</div>
            )}
            <div className="rss-item-date">{formatDate(data.isoDate)}</div>
          </div>
        </div>
        {children}
      </div>
    );
  }

  return (
    <div
      className={'rss-item rss-item-new' + (disableImage ? ' rss-item-image-disabled' : '')}
      data-disable-image={disableImage.toString()}
    >
      <LazyNewsImage
        data={data}
        lazyLoad={lazyLoadImage}
        disableImage={disableImage}
        defaultImage={
          ticker
            ? {
                url: 'https://iocharts-data.fra1.digitaloceanspaces.com/logos/' + ticker.toUpperCase() + '.png',
                width: 128,
                height: 128,
              }
            : null
        }
        fallbackImg={seekingAlpha ? 'https://iocharts-data.fra1.digitaloceanspaces.com/logos/sa_logo.webp' : null}
        fallbackImgSize={{ width: 128, height: 128 }}
      />
      <div className="rss-item-title">
        <a href={linkGenerator ? linkGenerator(data.link) : data.link} target="_blank" rel="noopener noreferrer">
          <div dangerouslySetInnerHTML={{ __html: data.title }} />
        </a>
      </div>
      {showPreview ||
      (showPreview === undefined &&
        (data.rssUrl?.startsWith('https://news.google.com') || data.link?.startsWith('https://seekingalpha.com'))) ? (
        data.og?.description ? (
          <div className="rss-item-description">{truncate(decode(data.og.description), 200)}</div>
        ) : (
          <div className="rss-item-description">
            <a href={linkGenerator ? linkGenerator(data.link) : data.link} target="_blank" rel="noopener noreferrer">
              {data.sourceUrl}
            </a>
          </div>
        )
      ) : details?.language === 'hu' && data.og?.description ? (
        <div className="rss-item-description">{truncate(decode(data.og.description), 200)}</div>
      ) : (
        <div className="rss-item-description">{truncate(decode(data.contentSnippet), 200)}</div>
      )}
      <div className="rss-item-date">{formatDate(data.isoDate)}</div>
      {children}
    </div>
  );
}

class SectionCompanyInfoNews extends Component<SectionCompanyInfoNewsProps & any, SectionCompanyInfoNewsState> {
  private allNewsFeed: any;
  private pressReleaseFeed: any;
  private isSeekingAlpha: boolean;

  constructor(props: SectionCompanyInfoNewsProps & any) {
    super(props);
    this.state = {
      allNewsReceived: true,
      pressReleaseReceived: true,
      activeTab: 'allNews',
      activePage: 1,
    };
    // {title:'Item1 asdjasdjasd',link:'https://investor.starbucks.com/press-releases/financial-releases/press-release-details/2020/Starbucks-Reports-Q1-Fiscal-2020-Results/default.aspx',description:'asdasdasdasd asd asdasdasdasdasdasd ajhweufweufhwueih euefhwiuefhiwehfsf sfuihefiuhe',date:'2018-02-01'},
    this.allNewsFeed = props.allNewsFeed;
    if (!props.allNewsFeed && props.seekingAlphaNews) {
      const skip = props.seekingAlphaNews.skip;
      this.allNewsFeed = props.seekingAlphaNews.pagination.items.slice(skip);
      this.isSeekingAlpha = true;
    } else {
      this.isSeekingAlpha = false;
    }
    this.pressReleaseFeed = props.pressReleaseFeed;
  }

  shouldComponentUpdate(
    props: Readonly<any>,
    nextState: Readonly<SectionCompanyInfoNewsState>,
    nextContext: any,
  ): boolean {
    if (
      this.props.seekingAlphaNews !== props.seekingAlphaNews ||
      this.props.allNewsFeed !== props.allNewsFeed ||
      this.props.pressReleaseFeed !== props.pressReleaseFeed
    ) {
      this.allNewsFeed = props.allNewsFeed;
      if (!props.allNewsFeed && props.seekingAlphaNews) {
        const skip = props.seekingAlphaNews.skip;
        this.allNewsFeed = props.seekingAlphaNews.pagination.items.slice(skip);
        this.isSeekingAlpha = true;
      } else {
        this.isSeekingAlpha = false;
      }
      this.pressReleaseFeed = props.pressReleaseFeed;
    }
    return true;
  }

  async feedReader() {
    const { pressReleaseFeed, allNewsFeed } = await CompanyService.getCompanyNews(
      this.props.ticker,
      this.props.router.locale,
      this.props.companyLinks,
    );
    this.pressReleaseFeed = pressReleaseFeed;
    this.allNewsFeed = allNewsFeed;
    this.setState({ pressReleaseReceived: true, allNewsReceived: true });
    /*
    const pressReleaseUrl = this.props.companyLinks ? this.props.companyLinks.rssPressRelease : '';

    const allNewsUrl = this.props.router.locale==='hu'
      ?
      this.props.companyLinks.rssNewsHungarian
      :
      'https://feeds.finance.yahoo.com/rss/2.0/headline?s=' + this.props.ticker;

    if (pressReleaseUrl) {
      const pressReleaseFeedItems = await RssApiService.getItemsByUrl(pressReleaseUrl);
      pressReleaseFeedItems.forEach((res) => this.pressReleaseFeed.push(res));
    }
    this.setState({pressReleaseReceived: true});

    const allNews = await RssApiService.getItemsByUrl(allNewsUrl);
    allNews.forEach((res) => this.allNewsFeed.push(res));
    this.setState({ allNewsReceived: true });
     */
  }
  changePage(direction: string) {
    console.log('change page: ' + direction);
    if (direction === 'plus') this.setState({ activePage: this.state.activePage + 1 });
    else this.setState({ activePage: this.state.activePage - 1 });
  }

  getFeed(type: string) {
    let rssFeed = [];
    let selectedFeed;
    if (type === 'allNews') selectedFeed = this.allNewsFeed;
    else selectedFeed = this.pressReleaseFeed;
    for (let i = 0; i < 10; i++) {
      if ((this.state.activePage - 1) * 10 + i > selectedFeed.length - 1) break;
      else rssFeed[i] = selectedFeed[(this.state.activePage - 1) * 10 + i];
    }
    return rssFeed;
  }

  async componentDidMount() {
    //this.feedReader();
  }

  changeTab(tab: string) {
    this.setState({ activeTab: tab, activePage: 1 });
  }

  render() {
    const { t } = getTranslation();
    return (
      <div className="section-company-info-youtube stock-page-news">
        <div className="section-header info">{t('section-news')}</div>
        <div className="section-submodule-with-tabs">
          <div className="section-submodule-tabs">
            {this.state.activeTab === 'allNews' ? (
              <span className="submodule-tabs-span-active">
                {this.isSeekingAlpha ? 'Seeking Alpha' : t('picker-allnews')}
              </span>
            ) : (
              <span className="submodule-tabs-span" onClick={() => this.changeTab('allNews')}>
                {this.isSeekingAlpha ? 'Seeking Alpha' : t('picker-allnews')}
              </span>
            )}
            {this.state.activeTab === 'pressRelease' ? (
              <span className="submodule-tabs-span-active">{t('picker-pressreleases')}</span>
            ) : (
              <span className="submodule-tabs-span" onClick={() => this.changeTab('pressRelease')}>
                {t('picker-pressreleases')}
              </span>
            )}
          </div>
          {!this.state.allNewsReceived ? (
            <LoadingComponent />
          ) : (
            <div className="section-submodule-content">
              {this.allNewsFeed.length === 0 && this.state.activeTab === 'allNews' ? (
                <span className="rss-nothing-found">{t('no-items')}</span>
              ) : (
                ''
              )}
              {this.pressReleaseFeed.length === 0 && this.state.activeTab === 'pressRelease' ? (
                <span className="rss-nothing-found">{t('no-items')}</span>
              ) : (
                ''
              )}
              <div className="rss-items-container">
                {this.getFeed(this.state.activeTab).map((data, index) => (
                  <React.Fragment key={index}>
                    {data.link.startsWith('https://seekingalpha.com') ? (
                      <NewsItem
                        oldVersion={true}
                        data={data}
                        ticker={this.props.ticker}
                        showPreview={true}
                        linkGenerator={(originalUrl) =>
                          'https://www.sahg6dtr.com/Z6FDGZ/R74QP/?url=' +
                          encodeURIComponent(
                            originalUrl +
                              '?source=affiliate_program:{affiliate_name}&utm_medium=affiliate&utm_source={affiliate_name}&affid={affiliate_id}&oid={offer_id}&transaction={transaction_id}',
                          )
                        }
                      />
                    ) : (
                      <NewsItem data={data} />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}
          <div className="section-submodule-tabs section-company-info-news-pagination">
            {this.isSeekingAlpha && (
              <span className={'seekingalpha-partner-notice'}>iO Charts is a Seeking Alpha partner</span>
            )}
            {this.state.activePage > 1 ? (
              <span className="submodule-tabs-span" onClick={() => this.changePage('minus')}>
                {t('picker-previouspage')}
              </span>
            ) : (
              ''
            )}
            {(this.state.activeTab === 'allNews' && this.allNewsFeed.length > this.state.activePage * 10) ||
            (this.state.activeTab === 'pressRelease' && this.pressReleaseFeed.length > this.state.activePage * 10) ? (
              <span className="submodule-tabs-span" onClick={() => this.changePage('plus')}>
                {t('picker-nextpage')}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SectionCompanyInfoNews);
